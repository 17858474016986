export const methodPayEs = {
  card: 'Tarjeta',
  paypal: 'Paypal',
  cash: 'Efectivo',
  spei: 'Transferencia eléctronica',
  deposito: 'Deposito'
}

export const statusPayEs = {
  payed: 'Pagado',
  pending: 'Pendiente',
  sent: 'Enviado',
  cancelled: 'Cancelado'
}

export const categories = {
  steakhouses: 'ASADORES',
  buildyourgrill: 'ARMA TU ASADOR',
  accesories: 'ACCESORIOS'
}

export const sections = {
  doorDesign: 'Diseño de puerta',
  grillType: 'Tipo de parrilla',
  sideTables: 'Mesas laterales',
  tiresType: 'Tipo de llantas',
  interiorType: 'Tipo de interior'
}

export const discountTypes = {
  porcent: 'Porcentaje',
  absolute: 'Absoluto'
}

export const social = {
  facebook: 'https://www.facebook.com/asadoresdelnorte',
  instagram: 'https://www.instagram.com/asadoresnorte',
  pinterest: 'https://www.pinterest.com.mx/asadoresdelnorte',
  youtube: 'https://www.youtube.com/user/AsadoresdelNorte'
}
