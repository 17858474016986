import React from 'react'
import asadoressrc from '../../../assets/new_3.jpg'
import accesoriossrc from '../../../assets/new_1.jpg'
import { useMediaQuery, Box } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import buildImageRsrc from '../../../assets/new_2.jpg'
import { Container, Title, ListContainer, Card, Picture, DataCategory, SubTitulo, ButtonStyled, TrendingFlatStyled } from './styles'
import { categories } from '../../../constants'
import slugify from 'slugify'

const Categories = props => {
  const isMobile = useMediaQuery('(max-width:959px)')
  const history = useHistory()

  const handleCategorySelected = (category) => {
    const slug = slugify(categories[category], {
      lower: true,
      strict: true
    })
    history.push(`/productos/${slug}`)
  }

  return (
    <>
      <Container>
        <Box pt={{ xs: 3, md: 4, lg: 5 }} />
        <Title style={{ marginBottom: isMobile ? '30px' : '80px' }} id='productos'>
          Productos
        </Title>
        <Box pt={{ xs: 2, md: 3, lg: 4 }} />
        <ListContainer>
          <Card>
            <Picture src={asadoressrc} />
            <DataCategory>
              <SubTitulo>ASADORES</SubTitulo>
              Construidos con procesos totalmente
              artesanales nuestros asadores
              combinan alto desempeño con un
              diseño rústico y poderoso.
              <Link as='div' onClick={() => handleCategorySelected('steakhouses')}>
                <ButtonStyled color='primary' variant='outlined' size='small'>
                  ASADORES <TrendingFlatStyled />
                </ButtonStyled>
              </Link>
            </DataCategory>
          </Card>
          <Card>
            <Picture src={buildImageRsrc} />
            <DataCategory>
              <SubTitulo>ARMA TU ASADOR</SubTitulo>
              Personaliza tu asador y elige los
              componentes que más te gusten.
              Agregale todo lo necesario para llevar
              tus parrilladas al siguiente nivel.

              <Link as='div' onClick={() => handleCategorySelected('buildyourgrill')}>
                <ButtonStyled color='primary' variant='outlined' size='small'>
                  ARMA TU ASADOR <TrendingFlatStyled />
                </ButtonStyled>
              </Link>
            </DataCategory>
          </Card>
          <Card>
            <Picture src={accesoriossrc} />
            <DataCategory>
              <SubTitulo>ACCESORIOS</SubTitulo>
              Un buen parrillero necesita
              herramientas. Nuestros accesorios
              están pensados para facilitar el arte
              de las brasas y proteger los asadores.

              <Link as='div' onClick={() => handleCategorySelected('accesories')}>
                <ButtonStyled color='primary' variant='outlined' size='small'>
                  ACCESORIOS <TrendingFlatStyled />
                </ButtonStyled>
              </Link>
            </DataCategory>
          </Card>
        </ListContainer>
      </Container>

    </>
  )
}

export default React.memo(Categories)
